import React from "react"

import { Cover } from "../../../anti/cover/cover"

export const ServicesDetailCover = ({ data }) => {
  return (
    <Cover
      variant="basic"
      theme="dark"
      img={data.image.sourceUrl}
      imgHeight="r-9-16 h-md-550px "
      // label="COMING SOON"
      // labelClassName="coming-soon"
      // title={data.title}
      // text={data.text}
      textClassName="mw-300px mw-md-500px"
      className="sc-services-detail-cover cover-full animated fadeInUp"
    >
      <h1 className={`cover-title d-flex align-items-center`}>
        {data.title}
        {data.label && (
          <span className="ml-3 coming-soon">{data.label}</span>
        )}
      </h1>
      <div
        className={`cover-text`}
        dangerouslySetInnerHTML={{
          __html: data.text,
        }}
      />
      {data.poweredBy && (
        <p className="cover-poweredby">
          <span>Powered by</span> {data.poweredBy}
        </p>
      )}
      <div className="services-circle">
        <img src={data.icon.sourceUrl} className="h-50px img-fluid" alt="img" />
      </div>
    </Cover>
  )
}
