import React from "react"

import Seo from "components/seo"
import Layout from "components/layout"

import { ServicesDetailCover } from "components/pages/services/detail/cover"

import { ServicesDetailServices } from "components/pages/services/detail/services"
import { Features } from "components/global/features/features"

const ServicesDetail = ({ pageContext }) => {
  const data = pageContext.data.service
  return (
    <Layout>
      <Seo title="Services" />
      <ServicesDetailCover data={data.coverdetail} />
      <ServicesDetailServices data={data.services} info={data.premiumInfo} premiumServices={data.premiumServices} />
      <Features data={data.features} />
    </Layout>
  )
}

export default ServicesDetail
