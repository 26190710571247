import React from "react"
import { Button } from "components/anti/buttons/buttons"

import { useScrollAnim } from "src/components/hooks/hooks"

export const ServicesDetailServices = ({ data, info, premiumServices }) => {
  const [trigger, anim] = useScrollAnim()
  return (
    <>
      <section className="pt-main sc-services-list list-main">
        <div className="container mw-xl">
          <h2 className="caption fadeInUp d3">Layanan</h2>
          <div className="service-list">
            <div className="row">
              {data.map((data, i) => (
                <div className="col-md-6" key={i}>
                  <div className={`service-item fadeInUp d${i + 4}`}>
                    {data.icon ? (
                      <img
                        src={data?.icon?.sourceUrl}
                        className="img-fluid"
                        alt={data.title}
                      />
                    ) : (
                      <div className="service-circle"></div>
                    )}
                    <div className="content">
                      <h5>{data.title}</h5>
                      <p className="mb-0">{data.text}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
      {premiumServices && (
        <section className="pb-main sc-services-list" ref={trigger}>
          <div className={`services-locked ${anim(1)}`}>
            <div className="container mw-xl">
              <img src={info.icon.sourceUrl} className="img-fluid" alt="Icon" />
              <p className="mb-0">{info.text}</p>
            </div>
          </div>
          <div className="container mw-xl">
            <div className="service-list">
              <div className="row">
                {premiumServices.map((data, i) => (
                  <div className="col-md-6" key={i}>
                    <div className={`service-item ${anim(2 + i)}`}>
                      {data.icon ? (
                        <img
                          src={data?.icon?.sourceUrl}
                          className="img-fluid"
                          alt={data.title}
                        />
                      ) : (
                        <div className="service-circle"></div>
                      )}
                      <div className="content">
                        <h5>{data.title}</h5>
                        <p className="mb-0">{data.text}</p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <hr className={anim(1)} />
            <div className={`btn-placeholder ${anim(2)}`}>
              <Button
                variant="link"
                className="btn-membership"
                link="/membership"
              >
                Tentang Membership
              </Button>
            </div>
          </div>
        </section>
      )}
    </>
  )
}
